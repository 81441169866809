import React from 'react';

// Layout Imports
import Default from '../layouts/default';
import Seo from '../layouts/seo';

// Component imports
import PageNotFound from '../components/page-not-found';

const NotFoundPage = () => {
  return (
    <Default>
      <Seo title='Page not found!' />

      <PageNotFound />
    </Default>
  );
};

export default NotFoundPage;