import React from 'react';
import { Link } from 'gatsby';

// Style Imports
import '../../sass/components/page-not-found.scss';

// Images
import Image from '../../../static/img/page-not-found.svg';

const PageNotFound = () => {
    return (
        <section className='page-not-found'>
            <span className='page-not-found__circles'></span>

            <div className='page-not-found__inner container'>
                <div className='page-not-found__image-wrapper'>
                    <img
                        className='page-not-found__image'
                        src={Image}
                        alt='Page not found graphic'
                    />
                </div>

                <h1 className='page-not-found__heading'>Page not found!</h1>

                <Link
                    className='page-not-found__link'
                    to="/"
                    onClick={() => window.scrollTo(0, 0)}
                >
                    Return to Homepage
                </Link>
            </div>
        </section>
    );
};

export default PageNotFound;
